
import { defineComponent, reactive } from 'vue';
import Button from './Button.vue';
import InputSelect from '@/components/Input/Select/InputSelect.vue';
import InputText from '@/components/Input/Text/InputText.vue';
import InputCheckbox from '@/components/Input/Checkbox/InputCheckbox.vue';
import { mockProps } from './mockup';
import { icons } from '@/models/components/icon.model';
import { sizes } from '@/models/components/size.model';
import { themesButton } from '@/models/components/theme.model';

export default defineComponent({
  name: 'SampleButton',
  components: {
    Button,
    InputSelect,
    InputText,
    InputCheckbox,
  },
  setup() {
    function clicked() {
      console.log('click');
    }

    return {
      props: reactive(mockProps),
      clicked,
      icons,
      sizes,
      themesButton,
    };
  },
});
