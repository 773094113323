export const mockProps = {
  theme: 'default',
  text: 'Button',
  title: 'Button title',
  href: 'https://couceramica.com/',
  to: { name: 'SampleStyleGuide'},
  icon: undefined,
  block: false,
  disabled: false,
  reverseOrder: false,
  size: 'md',
  hrefEnabled: false,
  toEnabled: false,
};